<template>
    <div id="app">
        <navbar />
        <router-view />
    </div>
</template>

<script>

export default {
    name: 'App',
}
</script>

<style>
    body {
        background-color: #F8F8FA;
        margin: 0px;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }
    body::-webkit-scrollbar {
        display: none;
    }
    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: #2c3e50;
        overflow: hidden;
    }
</style>
