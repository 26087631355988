import Vue from 'vue'
import App from './App.vue'
import router from './router/routing'

import VueGeolocation from 'vue-browser-geolocation';
Vue.use(VueGeolocation);

Vue.config.productionTip = false

import navbar from './components/navbar';
Vue.component('navbar', navbar);

import chillIcon from './components/chillIcon';
Vue.component('chillIcon', chillIcon);

import {getData} from "@/mixins/getData"
Vue.mixin(getData)



// import { initializeApp } from "firebase/app";

import firebase from 'firebase/compat/app';
const firebaseConfig = {
    apiKey: "AIzaSyDaz-4FRQ3B99BOW-qIcTix3AGkoKtnAXY",
    authDomain: "door-sealing.firebaseapp.com",
    projectId: "door-sealing",
    storageBucket: "door-sealing.appspot.com",
    messagingSenderId: "456545893856",
    appId: "1:456545893856:web:3f1be1e8b30b93d628bf85"
};

// initializeApp(firebaseConfig);
firebase.initializeApp(firebaseConfig);



new Vue({
    router,
    render: h => h(App),
}).$mount('#app')
