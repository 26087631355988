<template>
    <div class="markets-view">
        <div v-if="loadingMarkets" class="loading-info">
            <div class="loader"></div>
            <div class="loading-markets"><span>Marktdaten werden geladen, bitte warten</span></div>
        </div>
        <div v-else class="search-market">
            <div class="our-logo">
                <img src="https://usercontent.one/wp/chillservices.com/wp-content/uploads/2020/09/logo.png" alt="ChillServices">
            </div>
            <section class="info-box" :class="boxExpanded ? 'box-expanded' : 'box-shrinked'">
                <h4 class="info-header"><chillIcon class="icon" value="info_circle" />Web-App zur Dokumentation installierter Türdichtungen</h4>
                <p>
                    Diese App begleitet die Einbringung von Türdichtungen bei NK-Wandmöbeln. Die App soll die
                    Eingabe der Verbräuche der unterschiedlichen Türdichtungsprofile aufzeichnen. ACHTUNG: Die
                    App ersetzt nicht den Arbeitsnachweis (Zettel)!
                    Sofern Sie der Nutzung Ihrer Standortdaten zugestimmt haben, sind die drei nahen Märkte
                    aufgelistet.
                    Falls Sie die "Marktsuche" verwenden möchten, können Sie beliebige Kombinationen und
                    Reihenfolgen von <b>Marktnummer, Stadt, Straße und PLZ</b> eingeben. Bitte verwenden Sie nur
                    Leerzeichen als Trennzeichen!
                </p>
                <span class="toogle-info-box" @click="toogleInfoBox()" ><chillIcon class="icon" :value="boxExpanded ? 'arrow_down_circle' : 'arrow_up_circle'" /></span>
            </section>
            <!-- <img src="https://pixy.org/src2/582/5824870.jpg" alt="ChillServices" style="width:300px;height:300px;"> -->
            <section class="search-box">
                <label for="titleInput" class="title-input" >Marktsuche</label>
                <input class="search-market-input" id="titleInput" type="text" name="" v-model="filterMarketText" placeholder="Marketliste durchsuchen" >
            </section>
        </div>
        <div class="markets-content">
            <div class="markets-list">
                <label class="market-list-text" v-if="!loadingMarkets && !(posistionAllowed == 'granted' || filterMarketText)" >Keine Marktdaten zu Ihrer Geoposition bzw. Ihrer Suche gefunden</label>
                <label class="market-list-text" v-else-if="!loadingMarkets" >Märkte, die zu Ihrer Suche passen, werden unten aufgelistet</label>
                <router-link v-for="(val, i) of marketSearch()" :key="i+val" :to="{name: 'SealingMarket', params: { market: val.fbId, marketData: val }}" class="router-link" >
                    <div class="single-market-row">
                        <span>{{val.name}} {{val.market_id_rewe}}</span>
                        <span>{{val.street}} {{val.zip}} {{val.city}}</span>
                    </div>
                </router-link>
            </div>
        </div>
    </div>
</template>
<script>
import fakeMarkets from '@/assets/fakeMarkets'
export default {
    name: 'Markets',
    data() {
        return {
            lat: null,
            lng: null,
            position: null,
            loadingMarkets: false,
            fakeMarkets: fakeMarkets,
            filterMarketText: '',
            posistionAllowed: false,
            boxExpanded: false
        }
    },
    watch: {
        position: function(newValue) {
            if(newValue !== null) {
                navigator.permissions.query({ name: 'geolocation' }).then(permission => {
                    if(permission.state == 'granted')
                        this.posistionAllowed = 'granted'
                }, () => {
                    this.posistionAllowed = false
                })
            }
        },
        posistionAllowed: function(newValue, oldValue) {
            if(newValue == 'granted' && newValue !== oldValue)
                this.getLocation()
        }
    },
    computed: { },
    methods: {
        toogleInfoBox() {
            this.boxExpanded = !this.boxExpanded
        },
        search_func(markets, string) {
            let strings = string.split(' ')
            let re = []
            for(let m in markets){
                if(!markets[m].name) continue;
                
                let id = markets[m].market_id_rewe ? JSON.stringify(markets[m].market_id_rewe) : ''

                let name_parts = markets[m].name?.toLowerCase().split(' ')
                let street_parts = markets[m].street?.toLowerCase().split(' ')
                let city_parts = markets[m].city?.toLowerCase().split("/")

                let match = true
                for(let s of strings){
                    let name = name_parts.some(function (e){return e.includes(this)}, s)
                    let street = street_parts.some(function (e){return e.includes(this)}, s)
                    let city = city_parts.some(function (e){return e.includes(this)}, s)
                    if(
                        !name && !street && !city &&
                        !markets[m].zip?.toLowerCase().includes(s) &&
                        !id.includes(s)
                    ){
                        match = false
                        break;
                    }
                }
                if(match){
                    markets[m]['fbId'] = m 
                    re.push(markets[m])
                }
            }
            return re;
        },
        marketSearch() {
            let markets = this.allMarkets.data ? this.allMarkets.data : []
            if(markets.length == 0) return markets
            else if(this.filterMarketText == '') {
                // return markets within certain area
                let r = []
                for(let m in markets){
                    if(markets[m].geolocation.lat && markets[m].geolocation.lng && this.closeby(markets[m].geolocation.lat, markets[m].geolocation.lng)){
                        markets[m]['fbId'] = m 
                        r.push(markets[m])
                    }
                }
                this.loadingMarkets = false
                return r;
            }
            // for(let m in markets) {
            //     if(!markets[m].name ) continue;
                
            //     let s = this.filterMarketText.toLowerCase()
            //     let id = markets[m].market_id_rewe ? JSON.stringify(markets[m].market_id_rewe) : ''

            //     if(
            //         markets[m].name?.toLowerCase().includes(s) ||
            //         markets[m].city?.toLowerCase().includes(s) ||
            //         markets[m].street?.toLowerCase().includes(s) ||
            //         markets[m].zip?.toLowerCase().includes(s) ||
            //         id.includes(s)
            //     ){
            //         markets[m]['fbId'] = m 
            //         re.push(markets[m])
            //     }
            // }
            let re = this.search_func(markets, this.filterMarketText.toLowerCase())
            this.loadingMarkets = false
            return re;
        },
        callFbFunc() {
            this.getAllMarkets()
        },
        closeby(m_lat,m_lng){
            if(this.posistionAllowed == 'granted')
                return this.getDistance(this.lat, this.lng, m_lat, m_lng) < 1
            else
                return
        },
        getDistance(lat1, lon1, lat2, lon2) {
            var R = 6371 // km
            var dLat = this.toRad(lat2-lat1)
            var dLon = this.toRad(lon2-lon1)
            lat1 = this.toRad(lat1)
            lat2 = this.toRad(lat2)

            var a = Math.sin(dLat/2) * Math.sin(dLat/2) +
            Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1) * Math.cos(lat2)
            var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a))
            var d = R * c
            return d
        },
        toRad(Value) {
            return Value * Math.PI / 180
        },
        // getLocation() {

        // }
        getLocation() {
            let vm = this
            this.$getLocation()
            .then(coordinates => {
                vm.lat=coordinates.lat
                vm.lng=coordinates.lng
                vm.position = coordinates
                this.posistionAllowed = 'granted'
            }, () => {
                this.posistionAllowed = false
            })
        },
    },
    mounted() {
        this.getAllMarkets()
        this.getLocation()
        this.loadingMarkets = true
    }
}
</script>

<style lang="scss">
    .markets-view {
        width: 90vw;
        max-width: 800px;
        margin: 100px auto;
        .loading-info {
            text-align: center;
        }
        .search-market {
            .our-logo {
                margin-bottom: 40px;
                > img {
                    height: 60px;
                    width: auto;
                    margin-left: calc(50% - 120px);
                }
            }
            .info-box {
                position: relative;
                text-align: left;
                border-left: solid #1B608C 5px;
                margin: 0px auto;
                background-color: white;
                padding: 12px;
                box-sizing: border-box;
                -webkit-box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.20);
                -moz-box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.20);
                box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.20);
                .info-header {
                    margin: 0px;
                    display: flex;
                    > i {
                        margin-right: 6px;
                        font-size: 22px;
                    }
                }
                .toogle-info-box {
                    position: absolute;
                    top: 8px;
                    right: 8px;
                     > i {
                         font-size: 24px;
                     }
                }
            }
            .box-expanded {
                height: auto;
            }
            .box-shrinked {
                max-height: 42px;
                overflow: hidden;
            }
            .search-box {
                display: flex;
                flex-direction: column;
                margin: 40px 0px;
                text-align: left;
                .title-input {
                    font-size: 16px;
                    font-weight: 500;
                }
                .search-market-input {
                    background-color: #fff;
                    max-width: 100%;
                    padding-left: 4px;
                    font-size: 14px;
                    font-weight: 300;
                    height: 28px;

                    border: none;
                    outline: none;
                    border-bottom: solid transparent 2px;
                    &:focus {
                        border-bottom: solid #51d07d 1px;
                    }
                }
            }
        }
        .loader {
            display: inline-block;
            border: 8px solid #f3f3f3; /* Light grey */
            border-top: 8px solid #3498db; /* Blue */
            border-radius: 50%;
            width: 40px;
            height: 40px;
            animation: spin 2s linear infinite;
        }
        .markets-content {
            margin: 12px auto;
            // height: calc(100vh - (100px + 500px));
            .markets-list {
                text-align: left;
                .router-link {
                    color: black;
                    text-decoration: none !important;
                }
                .single-market-row {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    background-color: #fff;
                    border-left: solid #1B608C 5px;
                    margin: 8px 0px;
                    padding-left: 8px;
                    height: 70px;
                    text-align: left;
                    > span {
                        margin-top: auto;
                        margin-bottom: auto;
                        overflow: hidden;
                    }
                }
            }
        }
    }
    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
</style>