import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/functions';


export const getData = {
    data() {
        return {
            allMarkets: {},
            singleMarket: {}
        }
    },
    methods: {
        async getAllMarkets() {
            let functions = firebase.app().functions('europe-west1');
            let requestAllMarkets = functions.httpsCallable("getMarkets");
            requestAllMarkets().then(result => {
                this.allMarkets = result
            })
        },
        async getSingleMarket(market_id) {
            let functions = firebase.app().functions('europe-west1');
            let requestSingleMarkets = functions.httpsCallable("getSingleMarket");
            requestSingleMarkets({data: {market_id: market_id} })
            .then(result => {
                this.singleMarket = result
            })
        },
        updateSealingSession(doorsSealed, marketId) {
            let functions = firebase.app().functions('europe-west1');
            let callUpdateMarket = functions.httpsCallable("updateMarket");
            console.log(doorsSealed)
            callUpdateMarket({query: {marketId: marketId, doorsSealed: doorsSealed} })
            .then(result => {
                this.singleMarket = result.data
            })
        }
    }
}