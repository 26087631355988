<template>
    <div class="sealing-view">
        <section class="info-box" :class="boxExpanded ? 'box-expanded' : 'box-shrinked'">
            <h4 class="info-header"><chillIcon class="icon" value="info_circle" />Eingabe der montierten Türdichtungen</h4>
            <p>
                Bitte tragen Sie in den folgenden Feldern die Höhe und Breite der Türen ein und ob Sie an den
                angegebenen Türseiten vormontierte Schlitzschienen vorgefunden haben oder nicht. Falls es 
                mehrere Türvarianten im Markt gibt, nutzen Sie dieses Formular bitte mehrfach. Eine Liste
                früherer Montagen sehen Sie unten.
            </p>
            <span class="toogle-info-box" @click="toogleInfoBox()" ><chillIcon class="icon" :value="boxExpanded ? 'arrow_down_circle' : 'arrow_up_circle'" /></span>
        </section>
        <div class="market-name" v-if="hasMarketData">
            <h4>REWE {{tempMarket.name}}</h4>
            <div class="doors-sealed-update">
                <span class="error-msg" v-if="errors.length">
                    <span>Eine Angabe passt nicht, bitte überprüfen Sie:</span>
                    <span class="not-field-keys" v-for="error in errors" :key="error">{{ errorTranslate(error) }}</span>
                </span>
                <div class="form-row">
                    <label>Gesamtzahl Türen im Markt
                        <input type="number" name="" id="doors_sealed" v-model="doors_sealed.total_doors">
                    </label>
                </div>
                <div class="form-row">
                    <label>Türhöhe (cm)
                        <input type="number" name="" id="doors_sealed" v-model="doors_sealed.doors_height">
                    </label>
                </div>
                <div class="form-row">
                <label>Türbreite (cm)
                    <input type="number" name="" id="doors_sealed" v-model="doors_sealed.doors_width">
                </label>
                </div>
                <div class="form-row">
                    <label class="row-title">Hat die Tür auf der Griffseite eine T-Schlitzschiene?</label>
                    <span class="bool-element">
                        <label><input type="radio" :value="'true'" v-model="doors_sealed.door_handle_t_slot">Ja</label>
                        <label><input type="radio" :value="'false'" v-model="doors_sealed.door_handle_t_slot">Nein</label>
                    </span>
                </div>
                <div class="form-row">
                    <label class="row-title">Hat die Tür auf der Angelseite eine T-Schlitzschiene?</label>
                        <!-- <select v-model="doors_sealed.door_hinge_t_slot"><option value=""></option><option :value="'true'">Ja</option><option :value="'false'">Nein</option></select> -->
                        <span class="bool-element">
                            <label><input type="radio" :value="'true'" v-model="doors_sealed.door_hinge_t_slot">Ja</label>
                            <label><input type="radio" :value="'false'" v-model="doors_sealed.door_hinge_t_slot">Nein</label>
                        </span>
                </div>
                <!-- <label>door_bottom_t_slot
                    <select v-model="doors_sealed.door_bottom_t_slot"><option value=""></option><option :value="'false'">yes</option><option :value="'true'">no</option></select>
                </label> -->
                <div class="form-row">
                    <label>Anzahl Türen, bei denen Türdichtungen montiert wurden
                        <input type="number" name="" id="doors_sealed" v-model="doors_sealed.doors_sealed">
                    </label>
                </div>
                <button v-if="!uploadSealedDoors" class="submit-btn" @click="checkForm()" >Eingaben absenden</button>
                <div v-else class="loading-info">
                    <div class="loader"></div>
                    <div class="loading-markets">
                        <span>Liste zu den eingebauten Türdichtungen wurde aktualisiert</span>
                    </div>
                </div>
            </div>
        </div>
        <section v-else>
            <div class="loading-info">
                <div class="loader"></div>
                <div class="loading-markets">
                    <span>Marktdaten werden geladen, bitte warten</span>
                </div>
            </div>

        </section>
        <section class="history" v-if="hasMarketData">
            <h4 v-if="hasMarketData">Rückblick bisheriger Montagen für diesen Markt</h4>
            <table class="table-box" id="customers">
              <tr class="tr-row-header">
                <th>Datum</th>
                <th>Anzahl Türen</th>
                <th>Türhöhe (cm)</th>
                <th>Türbreite (cm)</th>
                <th>T-Nut auf Griffseite</th>
                <th>T-Nut auf Angelseite</th>
              </tr>
              <tr class="tr-row-body" v-for="sealed of sealedHistory" :key="sealed.sealed_at">
                  <th>{{timeConverter(sealed.sealed_at)}}</th>
                  <th>{{sealed.doors_sealed}}</th>
                  <th>{{sealed.doors_height}}</th>
                  <th>{{sealed.doors_width}}</th>
                  <th v-if="sealed.door_handle_t_slot ==='true'"> Ja </th>
                  <th v-else> Nein </th>
                  <th v-if="sealed.door_hinge_t_slot ==='true'"> Ja </th>
                  <th v-else> Nein </th>
              </tr>
            </table>
            <div class="total-number" v-if="tempMarket && tempMarket.doors_sealed_sessions.length > 0"><span> Anzahl der insgesamt montierten Türdichtungen: </span><span class="key-value"> {{this.tempMarket.doors_sealed}}</span></div>
            <h4 v-if="this.tempMarket">Gesamtmengen montierter Türdichtungen:</h4>
            <table class="table-box" v-if="this.tempMarket" id="customers">
                <tr class="tr-row-header">
                    <th>T-Profil (m)</th>
                    <th>Y-Profil (m)</th>
                    <th>I-Profil (m)</th>
                    <th>L-Profil (m)</th>
                </tr>
                <tr class="tr-row-body">
                    <th>{{tempMarket.t_shape ? tempMarket.t_shape/100 : 0}}</th>
                    <th>{{tempMarket.y_shape ? tempMarket.y_shape/100 : 0}}</th>
                    <th>{{tempMarket.i_shape ? tempMarket.i_shape/100 : 0}}</th>
                    <th>{{tempMarket.l_shape ? tempMarket.l_shape/100 : 0}}</th>
                </tr>
            </table>

            
        </section>
    </div>
</template>
<script>
export default {
    name: 'SealingMarket',
    data() {
        return {
            doors_sealed: { doors_height: '', doors_width: '', door_handle_t_slot: '', door_hinge_t_slot: '', doors_sealed: '' },
            tempMarket: null,
            loadingMarket: false,
            uploadSealedDoors: false,
            errors: [],
            boxExpanded: false
        }
    },
    computed: {
        sealedHistory() {
            if(this.tempMarket && this.tempMarket.doors_sealed_sessions)
                return [...this.tempMarket.doors_sealed_sessions].sort((firstEl, secondEl) => secondEl.sealed_at - firstEl.sealed_at )
            else
                return []
        },
        hasMarketData() {
            if(this.tempMarket && Object.keys(this.tempMarket).length > 0)
                return this.tempMarket
            else
                return false
        },
    },
    watch: {
        tempMarket: function(newval) {
            if(Object.keys(newval).length > 0){
                this.uploadSealedDoors = false
                this.loadingMarket = false
            }
        },
        singleMarket: function() {
            if(this.singleMarket.data)
                this.tempMarket = {...this.singleMarket.data}
        }
    },
    methods: {
        toogleInfoBox() {
            console.log('toogle func check: ', this.boxExpanded)
            this.boxExpanded = !this.boxExpanded
        },
         errorTranslate(slug) {
            let translate = { doors_height: 'Türhöhe (cm)', doors_width: 'Türbreite (cm)', door_handle_t_slot: 'Hat die Tür auf der Griffseite eine T-Schlitzschiene?', door_hinge_t_slot: 'Hat die Tür auf der Angelseite eine T-Schlitzschiene?', doors_sealed: 'Anzahl der insgesamt montierten Türdichtungen' }

            return translate[slug]
        },
        doors_sealed_filled() {
            const not_filled = Object.keys(this.doors_sealed).filter((x, i) => Object.values(this.doors_sealed)[i] === '' ? x : null )
            return not_filled
        },
        checkForm() {
            let notFilled = this.doors_sealed_filled()
            this.errors = []
            if(notFilled.length > 0) {
                notFilled.forEach(x => {
                    this.errors.push(x)
                })
            }
            else {
                this.updateMarketVisit()
            }
        },
        updateMarketVisit() {
            Object.keys(this.doors_sealed).forEach(key => {
                this.doors_sealed[key] = !isNaN(this.doors_sealed[key]) ? parseInt(this.doors_sealed[key]) : this.doors_sealed[key]
            })
            this.uploadSealedDoors = true
            this.updateSealingSession(this.doors_sealed, this.$route.params.market)
            this.doors_sealed =  {
                doors_height: '', doors_width: '', door_handle_t_slot: '', door_hinge_t_slot: '', doors_sealed: ''
            }
        },
        timeConverter(UNIX_timestamp){
            if(typeof UNIX_timestamp === 'number') {
                var a = new Date(+UNIX_timestamp );
                
                var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
                var year = a.getFullYear(), month = months[a.getMonth()], date = a.getDate();
                var time = date + ' ' + month + ' ' + year + ' ' ;
                return time
            }
            else return 'missing_date'
        }
    },
    mounted() {
        this.loadingMarket = true
        this.getSingleMarket(this.$route.params.market)
    }
}
</script>
<style lang="scss">
    .sealing-view {
        width: 90vw;
        max-width: 800px;
        margin: 100px auto;
        .info-box {
            position: relative;
            text-align: left;
            border-left: solid #1B608C 5px;
            margin: 0px auto;
            background-color: white;
            padding: 12px;
            box-sizing: border-box;
            -webkit-box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.20);
            -moz-box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.20);
            box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.20);
            .info-header {
                display: flex;
                margin: 0px;
                > i {
                    margin-right: 6px;
                    font-size: 22px;
                }
            }
            .toogle-info-box {
                position: absolute;
                top: 8px;
                right: 8px;
                    > i {
                        font-size: 24px;
                    }
            }

        }
        .box-expanded {
            height: auto;
        }
        .box-shrinked {
            max-height: 42px;
            overflow: hidden;
        }
        .market-name {
            display: flex;
            flex-direction: column;
            margin: 40px auto;
            .doors-sealed-update {
                text-align: start;
                .loading-info {
                    text-align: center;
                    display: inline-block;
                    .loader {
                        margin-top:8px;
                        display: inline-block;
                        border: 8px solid #f3f3f3; /* Light grey */
                        border-top: 8px solid #3498db; /* Blue */
                        border-radius: 50%;
                        width: 16px;
                        height: 16px;
                        animation: spin 2s linear infinite;
                    }
                }
            }
        }
        .loading-info {
            text-align: center;
            .loader {
                margin-top:8px;
                display: inline-block;
                border: 8px solid #f3f3f3; /* Light grey */
                border-top: 8px solid #3498db; /* Blue */
                border-radius: 50%;
                width: 40px;
                height: 40px;
                animation: spin 2s linear infinite;
            }
        }
        .history {
            .table-box {
                width: 100%;
                margin: auto;
                background-color: #fff;
                border: none;
                .tr-row-header {
                    border: none;
                    background-color: #CBCCD5;
                    > th {
                        font-size: 13px;
                        font-weight: 600;
                        border: none;
                    }
                }
                .tr-row-body {
                    > th {
                        font-size: 14px;
                        font-weight: 300;
                        border: none;
                        border-bottom: solid #CBCCD5 0.5px;

                    }
                    &:last-child {
                        > th {
                            border-bottom: none;
                        }
                    }
                }
            }
            margin: 0px auto;
            .sealed-history {
                text-align: left;
                margin: 4px 0px;
            }
            .total-number {
                text-align: right;
                margin-bottom: 24px;
                margin-top: 6px;
            }
        }
    }
    .form-row {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        background-color: #fff;
        border-left: solid #1B608C 5px;
        box-sizing: border-box;
        margin: 8px 0px;
        padding: 8px;
        min-height: 65px;
        text-align: left;
        label {
            display: flex;
            flex-direction: column;
            text-align: left;
            margin-bottom: 4px;
            width: 100%;
            > input {
                border: none;
                outline: none;
                border-bottom: solid #565760 1px;
                &:focus {
                    border-bottom: solid #51d07d 1px;
                }
            }
            
        }
        .bool-element {
            display: flex;
            margin: 0px 12px;
            width: 100%;
            
            > label {
                width: 30%;
                &:nth-child(2) {
                    align-items: center;
                }
            }
        }
    }
    input[type="number"] {
        font-size: 16px;
        width: calc(100% - 8px);
        height: 28px;
        border: solid black 0.5px;
    }
    button {
        height: 28px;
        margin: 20px 0px;
        font-size: 16px;
        border-radius: 4px;
        border: solid black 0.5px;;
    }
    h4 {
        text-align: left;
        margin-top: 0px;
    }
    select {
        font-size: 16px;
        width: 100%;
        height: 28px;
        border-radius: 4px;
        border: solid black 0.5px;
        &:hover {
            cursor: pointer;
        }
    }
    .key-value {
        font-weight: 500;
    }
    .total-number {
        text-align: left;
    }
    .total-doors {
        text-align: left;

    }
    .not-field-keys {
        color: red;
        margin-right:8px;
        display: block;
    }
    .error-msg {
        display: block;
        text-align: left;
        margin-bottom: 8px;
    }
    .submit-btn {
        height: 28px;
        border-radius: 4px;
        border: none;
        background-color: #1B608C;
        color: #fff;
        padding: 0px 12px;
        &:hover {
            cursor: pointer;
        }
    }
    p {
        text-align: left;
    }
    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
    table, th, td {
        border-collapse: collapse;border: 1px solid black;
    }
    h4 {
        margin: 0px;
    }
    input[type="radio"] {
        margin: 0px;
    }
</style>