<template>
    <div class="navbar">
        <div class="navbar-content">
            <router-link v-if="$route.name !== 'Markets' "  to="/">
                <chillIcon class="icon" value="icon_arrow_left" />
            </router-link>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'navbar',
        data() {
            return {

            }
        }
    }
</script>

<style lang="scss">
    .navbar {
        position: fixed;
        top: 0px;
        left: 0px;
        right: 0px;
        height: 60px;
        text-align: left;
        background-color: #F8F8FA;
        .navbar-content {
            height: 100%;
            margin-left: 20px;
            display: flex;
            align-items: center;
            .icon {
                font-size: 24px;
                font-weight: 600;
                width: 28px;
            }
        }
    }
</style>