import Vue from "vue"
import VueRouter from 'vue-router';
import Markets from '@/views/Markets';
import SealingMarket from '@/views/SealingMarket';

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Markets',
        component: Markets
        // meta: { requiresAuth: true }
    },
    {
        path: '/SealingMarket/:market',
        name: 'SealingMarket',
        component: SealingMarket
    }
]

const router = new VueRouter({
    routes
})

export default router