<template>
	<i v-if="type === 'material'" :class="type" class="material-chill-icons">{{ value }}</i>
	<i v-else :class="type" class="chill-icons">{{ value }}</i>
</template>

<script>
    export default {
        name: "chillIcon",
        props: {
            value: String,
            type: String
        }
    }
</script>

<style lang="scss">
    @font-face {
	font-family: "Chill Icons";
    src:
        url("../assets/fonts/ChillServicesIcons-2021.ttf") format("truetype"),
        url("../assets/fonts/ChillServicesIcons-2021.eot") format("eot"),
        url("../assets/fonts/ChillServicesIcons-2021.svg") format("svg"),
        url("../assets/fonts/ChillServicesIcons-2021.woff") format("woff");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "Material Chill Icons";
    src:
        url("../assets/fonts/ChillServicesIcons-2021.ttf") format("truetype");
	font-weight: normal;
	font-style: normal;
}

.material-chill-icons {
	font-family: "Material Chill Icons";
	font-weight: normal;
	font-style: normal;
	display: inline-block;
	line-height: 1;
	text-transform: none;
	letter-spacing: normal;
	word-wrap: normal;
	white-space: nowrap;
	direction: ltr;
	-webkit-font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
	-moz-osx-font-smoothing: grayscale;
	font-feature-settings: "liga";
}

.chill-icons {
	font-family: "Chill Icons";
	font-weight: normal;
	font-style: normal;
	display: inline-block;
	line-height: 1;
	text-transform: none;
	letter-spacing: normal;
	word-wrap: normal;
	white-space: nowrap;
	direction: ltr;
	-webkit-font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
	-moz-osx-font-smoothing: grayscale;
	font-feature-settings: "liga";
}

</style>